import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login, resetAuth } from '../../redux/features/auth/authSlice';
import useApiCallStatusNotificationHandler from '../../utils/apiCallStatusHandler';
import './login.scss';
import loginLogo from '../../assets/img/maintenance.png';
import foxLogo from '../../assets/img/FOX-Logo3.png';
import Protected from '../../utils/protected';

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

export default function Login () {
  const history = useHistory();
  const { user, isLoading, isError, isSuccess } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isShow, setIsShow] = useState(false);
  const handleSubmit = e => {
    const userInfo = { username, password };

    dispatch(login(userInfo));

    e.preventDefault();
  };

  const { showSpinner } = useApiCallStatusNotificationHandler({
    isLoading,
    isError,
  });

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        history.push('/');
      }, 100)
    }
  }, [isSuccess])
  

  useEffect(() => {
    dispatch(resetAuth());
  }, [user, isError, isSuccess, isLoading, dispatch, history]);

  return (
    <Protected>
      <div className="login-container">
        {showSpinner ? <div id="loader" /> : null}
        <div className="row d-flex m-2 mt-5 justify-content-center ">
          <div
            id="carouselExampleIndicators"
            className="carousel slide  my-auto col-lg-4 col-sm-12"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active inline-main"
              />
            </ol>
            <div className="carousel-inner ">
              <div className="carousel-item active d-flex justify-content-center">
                <div className="col-lg-12 col-md-6 col-sm-6 inline-carousel-item">
                  <div className="text-center inline-maintain-logo">
                    <img alt="maintain logo" src={loginLogo} />
                  </div>
                </div>
              </div>
            </div>
            <button type="button" className="carousel-control-prev" href="#">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" href="#" type="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </button>
          </div>
          <div className="col-lg-1 col-sm-12" />
          <div className="login-box justify-content-center my-auto inline-login-box">
            <div className="mb-5 row justify-content-center">
              <img alt="logo" src={foxLogo} height="100" />
            </div>

            <div className="">
              <form onSubmit={handleSubmit}>
                {/* <div id="error_msg"> */}
                {/*  <div className="box bg-danger inline-error-box"> */}
                {/*    <center> */}
                {/*      <span>message</span> */}
                {/*    </center> */}
                {/*  </div> */}
                {/*  <br /> */}
                {/* </div> */}
                <div className="mb-4">
                  <input
                    name="username"
                    type="text"
                    className="form-control inline-username"
                    placeholder="Username"
                    id="username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                  />
                  <div className="col-lg-12 col-sm-12 m-0">
                    <span className="font-danger" id="username_error" />
                  </div>
                </div>
                <div className="input-group mb-2">
                  <input
                    name="password"
                    value={password}
                    type={!isShow ? 'password' : 'text'}
                    className="form-control inline-password"
                    placeholder="Password"
                    id="password"
                    onChange={e => setPassword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text inline-show-password pointer">
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => setIsShow(!isShow)}
                        title={!isShow ? 'Show password' : 'Hide password'}
                        id="eye"
                      >
                        {isShow ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}{' '}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12 m-0">
                    <span className="font-danger" id="password_error" />
                  </div>
                </div>
                <div className="mb-5 col-lg-12 col-sm-12 m-0 inline-forgot-password">
                  Forgot your password?{' '}
                  <a className="  " href="/forgot-password">
                    Reset password
                  </a>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-12 m-0">
                    <button type="submit" className="btn btn-block primary-btn inline-login-btn">
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="footer text-center col-lg-12 my-3 inline-footer">
          {' '}
          © 2023 Infinity Wave Sdn. Bhd.
        </div>
      </div>
    </Protected>
  );
}
